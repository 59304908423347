import React from 'react';

function Footer() {
  return (
    <footer 
      style={{
        backgroundColor: '#18181b',
        padding: '10px',
        textAlign: 'center',
        color: '#b4bcd0',
        position: 'fixed',
        left: '0',
        bottom: '0',
        width: '100%',
        boxSizing: 'border-box',
        zIndex: '1000' // Ensures the footer is always on top
      }} 
      role="contentinfo"
    >
      <p>Copyright 2024, pwrwpw. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
