import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Main.css';

function Main() {
  const navigate = useNavigate();

  const handleBlogButtonClick = () => {
    window.open('https://blog.pwrwpw.com/', '_blank');
  };

  const handleProjectButtonClick = () => {
    navigate('/start-chat');
  };

  return (
    <main className="main-custom">
      <p>안녕하세요, 이현석입니다.</p>
      <div className="button-container">
        <button className="button-custom" onClick={handleBlogButtonClick}>
          블로그
        </button>
        <button className="button-custom" onClick={handleProjectButtonClick}>
          채팅방
        </button>
      </div>
    </main>
  );
}

export default Main;