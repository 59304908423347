import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Main from './components/Main';
import StartChat from './components/StartChat.js';
import Chat from './components/Chat.js';
import Footer from './components/Footer.js';
import NotFound from './components/NotFound.js';
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/start-chat" element={<StartChat />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="*" element={<NotFound />} />
        </Routes>
      <Footer />
    </Router>
  );
}

export default App;
