import React, { useState, useCallback, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import './Chat.css';

const SOCKET_SERVER_URL = 'https://chat.pwrwpw.com';

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState('');
  const [users, setUsers] = useState([]);
  const [currentUserNickname, setCurrentUserNickname] = useState('');

  const socket = useRef(null);
  const reconnectTimeout = useRef(null);
  const messagesEndRef = useRef(null); // 스크롤을 이동시킬 참조

  // 소켓 초기화
  const initializeSocket = useCallback(() => {
    if (socket.current) {
      socket.current.disconnect(); // 기존 연결 종료
    }

    socket.current = io(SOCKET_SERVER_URL);

    // 이벤트 핸들러
    const handleUserJoined = (data) => {
      setCurrentUserNickname(data.sender);
    };

    const handleReceiveMessage = (msg) => {
      setMessages((prevMessages) => [...prevMessages, msg]);
    };

    const handleChatMessage = (msg) => {
      setMessages((prevMessages) => [...prevMessages, { sender: 'System', message: msg.message }]);
    };

    const handleReceiveUsers = (nicknames) => {
      setUsers(nicknames);
    };

    // 이벤트 리스너 등록
    socket.current.on('user_joined', handleUserJoined);
    socket.current.on('receive_message', handleReceiveMessage);
    socket.current.on('receive_users', handleReceiveUsers);
    socket.current.on('chat_message', handleChatMessage);

    // 소켓 연결 끊김 처리
    socket.current.on('disconnect', () => {
      console.log('소켓 연결이 끊어졌습니다. 재연결을 시도합니다...');
      if (reconnectTimeout.current) {
        clearTimeout(reconnectTimeout.current);
      }
      reconnectTimeout.current = setTimeout(() => {
        initializeSocket();
      }, 5000); // 5초 후 재연결 시도
    });

    // 컴포넌트 언마운트 시 소켓 및 타임아웃 정리
    return () => {
      if (socket.current) {
        socket.current.off('user_joined', handleUserJoined);
        socket.current.off('receive_message', handleReceiveMessage);
        socket.current.off('receive_users', handleReceiveUsers);
        socket.current.off('chat_message', handleChatMessage);
        socket.current.disconnect(); // 연결 종료
      }
      if (reconnectTimeout.current) {
        clearTimeout(reconnectTimeout.current);
      }
    };
  }, []);

  // 메시지 전송 함수
  const sendMessage = useCallback(() => {
    if (!messageText.trim()) {
      alert('메시지를 입력하세요.');
      return;
    }

    if (socket.current) {
      socket.current.emit('send_message', {
        sender: currentUserNickname,
        message: messageText
      });
      setMessageText(''); // 메시지 입력 필드 비우기
    }
  }, [messageText, currentUserNickname]);

  // Enter 키 입력 처리
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.nativeEvent.isComposing) {
      e.preventDefault(); // 기본 동작 방지
      sendMessage(); // 메시지 전송 함수 호출
    }
  };

  useEffect(() => {
    const cleanup = initializeSocket();

    return cleanup; // 컴포넌트 언마운트 시 소켓 및 타임아웃 정리
  }, [initializeSocket]);

  // 메시지 배열이 변경될 때 스크롤을 하단으로 이동
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  return (
    <div className="chatroom-container">
      <div className="chatbox">
        <div className="chat-header">
          채팅방 <span className="user-count">({users.length}명)</span>
        </div>
        <div className="message-container">
          {messages.map((message, index) => (
            <div key={index} className={`message ${message.sender}`}>
              <div className="message-content">
                <strong>{message.sender}:</strong> {message.message}
              </div>
            </div>
          ))}
          <div ref={messagesEndRef} /> {/* 스크롤을 이동시킬 참조 */}
        </div>
        <div className="input-section">
          <input
            className="input-field"
            type="text"
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="채팅 입력"
          />
          <button className="send-button" onClick={sendMessage}>
            ➤
          </button>
        </div>
      </div>
      <div className="userlist">
        <div className="userlist-header">{currentUserNickname}</div>
        {users.map((user, index) => (
          user !== currentUserNickname ? (
            <div key={index} className="user">
              {user}
            </div>
          ) : null
        ))}
      </div>
    </div>
  );
};

export default Chat;

