import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './StartChat.module.css';

const StartChat = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleLocationChange = (path) => {
    window.location.href = path;
  }

  return (
    <div className={styles.imgContainer}>
      <img src="/img.jpg" alt="Background" className={styles.backgroundImage} />
      <h1 className={styles.heading}>익명 채팅방</h1>
      <button className={styles.overlayButton} onClick={() => handleLocationChange('/chat')}>
        채팅방으로 이동
      </button>
      <button className={styles.overlayButton} onClick={() => handleNavigation('/')}>
        메인으로 이동
      </button>
    </div>
  );
};

export default StartChat;