// src/components/NotFound.js
import React from 'react';
import './NotFound.css';

const NotFound = () => {
  return (
    <div className="error-container">
      <div className="error-code">
        <span>4</span>
        <span>0</span>
        <span>4</span>
      </div>
      <div className="error-title">죄송합니다.</div>
      <div className="error-message">요청하신 페이지를 찾을 수 없습니다.</div>
      <div className="error-message">
      방문하시려는 페이지의 주소가 잘못 입력되었거나,
      </div>
      <div className="error-message">
      페이지의 주소가 변경 혹은 삭제되어 요청하신 페이지를 찾을 수 없습니다.
        </div>
    </div>
  );
};

export default NotFound;
